<template>
  <div>
    <!-- ADD PROJECT FORM  -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/apps/projects">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add Project">
          <div class="col-12 mb-3 text-right" v-if="checkUserType == 2">
            <vs-button @click="checkAdvancedOption"
              >{{ form.isAdvanced == 2 ? "Show" : "Hide" }} Advanced
              Option</vs-button
            >
          </div>
          <form>
            <div class="row">
              <div class="col-6 mb-3">
                <h6>Company Name*</h6>
                <multiselect
                  v-validate="'required'"
                  v-model="company_value"
                  track-by="company_id"
                  label="company_name"
                  @input="
                    ({ company_id }) => (this.form.company_id = company_id)
                  "
                  :options="companies"
                  name="Company Name"
                  :searchable="false"
                  :allow-empty="true"
                  open-direction="bottom"
                  deselect-label="Can't remove this value"
                  @select="onSelectCompany"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.company_name
                  }}</template>
                </multiselect>
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Company Name')"
                    >{{ errors.first("Company Name") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-3">
                <h6>Project Name *</h6>
                <vs-input
                  v-validate="'required'"
                  name="project name"
                  v-model="form.project_name"
                  class="w-full"
                  rules="alpha"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('project name')"
                    >{{ errors.first("project name") }}</span
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 mb-2">
                <h6>Type of Project</h6>
                <vs-input
                  type="text"
                  name="Type of Project"
                  v-model="form.project_type"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Type of Project')"
                    >{{ errors.first("Type of Project") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-2">
                <h6>Type of Show</h6>
                <vs-input
                  type="text"
                  name="Type of Show"
                  v-model="form.show_type"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Type of Show')"
                    >{{ errors.first("Type of Show") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-2">
                <h6>Directors</h6>
                <vs-input
                  type="text"
                  name="Directors"
                  v-model="form.directors"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Directors')"
                    >{{ errors.first("Directors") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-2">
                <h6>Writers</h6>
                <vs-input
                  type="text"
                  name="Writers"
                  v-model="form.writers"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Writers')"
                    >{{ errors.first("Writers") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-2">
                <h6>Lead Cast</h6>
                <vs-input
                  type="text"
                  name="Lead Cast"
                  v-model="form.lead_cast"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Lead Cast')"
                    >{{ errors.first("Lead Cast") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-2">
                <h6>Internal CD</h6>
                <vs-input
                  type="text"
                  name="Internal CD"
                  v-model="form.internal_cd"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Internal CD')"
                    >{{ errors.first("Internal CD") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-2">
                <h6>No. of Episodes</h6>
                <vs-input
                  onkeydown="javascript: return event.keyCode == 69 ? false : true"
                  type="number"
                  name="No. of episodes"
                  v-model="form.episodes"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('No. of episodes')"
                    >{{ errors.first("No. of episodes") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-2">
                <h6>Shoot Days</h6>
                <vs-input
                  type="number"
                  name="Shoot Days"
                  v-model="form.shoot_days"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Shoot Days')"
                    >{{ errors.first("Shoot Days") }}</span
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 mb-3">
                <div class="mb-4">
                  <h6>Description</h6>
                  <vs-textarea
                    v-model="project_details"
                    class="mb-0"
                    rows="4"
                  />
                </div>
              </div>

              <div class="col-6 mb-3">
                <h6>Upload Project Icon</h6>
                <vue-dropzone
                  @vdropzone-error="vdropzoneError"
                  @vdropzone-complete="vdropzoneComplete"
                  @vdropzone-files-added="vdropzoneFilesAdded"
                  @vdropzone-success="vdropzoneSuccess"
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                ></vue-dropzone>
                <vs-button
                  v-if="imageUpload.deleteButtonStatus"
                  type="filled"
                  @click.prevent="clearImage()"
                  class="mr-3"
                >
                  <i class="mr-3 feather icon-trash"></i>
                </vs-button>
              </div>
            </div>
            <div class="row">
              <div class="col-2 mb-3">
                <h6>Budget Active</h6>
                <b-form-checkbox
                  value="1"
                  unchecked-value="0"
                  v-model="form.project_budgetactive"
                  name="check-button"
                  switch
                ></b-form-checkbox>
              </div>
              <div
                class="col-3 mb-3"
                v-if="checkUserType == 1 || checkUserType == 2"
              >
                <h6>Contract Budget Active</h6>
                <b-form-checkbox
                  value="1"
                  unchecked-value="0"
                  v-model="form.contract_budgetactive"
                  name="check-button"
                  switch
                ></b-form-checkbox>
              </div>
            </div>

            <!-- ADD LEVELS CARD  -->
            <div
              v-for="(level, itemKey) in levels"
              :key="itemKey"
              class="vx-col md:w-3/4 mx-auto"
              :v-if="form.isAdvanced == 1"
            >
              <h4 class="font-bold ml-1 text-success">
                Level {{ level.levelName }}
              </h4>
              <div class="vx-row mb-base">
                <div class="vx-col w-5/6 mx-auto">
                  <label class="typo__label">Single select / dropdown</label>
                  <multiselect
                    v-model="level.userSelected"
                    track-by="name"
                    :multiple="true"
                    label="name"
                    :options="userDataAvailable"
                    :searchable="true"
                    :allow-empty="true"
                    open-direction="bottom"
                    deselect-label="Can't remove this value"
                    @select="optionSelected($event, itemKey)"
                    @remove="optionRemoved"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      <strong>{{ option.name + option.city_name }}</strong>
                    </template>
                  </multiselect>
                </div>
                <div class="vx-col w-1/6 mx-auto align-self-end">
                  <feather-icon
                    v-on:click="onRemoveLevel(level)"
                    v-if="itemKey == [levels.length - 1] && itemKey != 0"
                    icon="XIcon"
                    class="text-danger font-weight-bold cursor-pointer"
                    svgClasses="h-9 w-9"
                  />
                </div>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  type="filled"
                  color="success"
                  :disabled="
                    (count > 2 && !currentLevelUsers) ||
                    userDataAvailable.length == 0
                  "
                  @click.prevent="onAddLevel()"
                  class="mr-3"
                  v-if="form.isAdvanced == 1"
                  >Add Level {{ count }}</vs-button
                >
                <vs-button
                  :disabled="submitStatus || !isUserSelected"
                  type="filled"
                  @click.prevent="submitForm()"
                  class="mr-3"
                  >Save Project</vs-button
                >

                <vs-button
                  color="warning"
                  type="border"
                  class
                  @click.prevent="clearForm()"
                  >Reset</vs-button
                >
              </div>
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index";
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import { VTree, VSelectTree } from "vue-tree-halower";
import projectService from "@/services/projectService.js";
import UserService from "@/services/UserService.js";
import uploadService from "@/services/uploadService.js";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const VxTour = () => import("@/components/VxTour.vue");
import companyService from "@/services/companyService.js";
import constant from "@/helper/constant";

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import { error } from "util";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
    VTree,
    VSelectTree,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      LevelAllList: [],
      submitStatus: true,
      dropzoneOptions: {
        url: BASEURL() + "/v2/multipleUploads?organization=",
        ...constant.DEFAULT_IMAGE,
        acceptedFiles: "image/*",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
      },
      imageUpload: {
        image_url: "",
        deleteButtonStatus: false,
      },
      moderator_user_id: "",
      finance_user_id: "",
      form: {
        project_name: "",
        adminManager_user_id: 2,
        moderator_user_id: 2,
        project_icon: "-",
        company_id: "",
        project_active: 1,
        project_budgetactive: 1,
        contract_budgetactive: 0,
        isAdvanced: 2,
        project_type: "",
        show_type: "",
        directors: "",
        writers: "",
        lead_cast: "",
        internal_cd: "",
        episodes: "",
        shoot_days: "",
      },
      project_details: "",
      currentLevel: {
        levelName: "1",
      },
      User: "Select User",
      levels: [
        {
          levelName: "1",
          Levellist: [],
        },
      ],
      count: 2,
      searchQuery: "",
      gridOptions: {},
      adminManager_value: [],
      AuditorList: [],
      adminManegerUsers: [],
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      company_value: [],
      auditor_value: [],
      finance_value: [],
      contacts: contacts,
      // TREE
      companies: [],
      initSelected: ["node-1"],
      userDataAll: [],
      financeUsers: [],
      userDataAvailable: [],
      userDataUnselected: [],
    };
  },
  beforeMount() {
    this.dropzoneOptions["url"] =
      BASEURL() + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptions["headers"]["authorization"] = this.access_token;
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    form: {
      handler: function (newVal, oldVal) {
        let len = 0;
        if (this.form.isAdvanced == 1) {
          len = Object.keys(this.form).length;
        } else {
          len = Object.keys(this.form).length;
        }

        if (
          len ===
            Object.keys(newVal).filter(
              (x, index) =>
                newVal[x] &&
                newVal[x] !== undefined &&
                String(newVal[x]).trim().length > 0
            ).length ||
          (newVal["project_name"] != "" && newVal["company_id"] != "")
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    organization_id() {
      return window.localStorage.getItem("OrganizationID");
    },
    checkUserType() {
      return window.localStorage.getItem("UserType");
    },
    isUserSelected() {
      if (this.form.isAdvanced == 1) {
        return (
          this.levels.filter((level) => {
            if (level.hasOwnProperty("userSelected")) {
              return level.userSelected.length > 0;
            } else {
              [];
            }
          }).length > 0
        );
      } else {
        return true;
      }
    },
    currentLevelUsers() {
      if (this.currentLevel.hasOwnProperty("userSelected")) {
        if (this.currentLevel.userSelected.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    checkAdvancedOption() {
      this.form.isAdvanced = this.form.isAdvanced == 2 ? 1 : 2;
    },
    onSelectModerator: function (selectedOption, id) {
      this.moderator_user_id = selectedOption.user_id;
      this.form.moderator_user_id = selectedOption.user_id;
    },
    optionRemovedModerator: function (removedOption, id) {
      this.userDataAvailable.push(removedOption);
    },
    optionSelected: function (selectedOption, id) {
      this.userDataAvailable = this.userDataAvailable.filter(function (obj) {
        return obj.user_id !== selectedOption.user_id;
      });
    },
    optionRemoved: function (removedOption, id) {
      this.userDataAvailable.push(removedOption);
    },
    clearImage: function () {
      this.$refs.myVueDropzone.removeAllFiles();
      this.imageUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzone.enable();
    },
    vdropzoneFilesAdded: function () {},
    vdropzoneSuccess: function (file, response) {
      this.form.project_icon = response.data.image_path;
      this.imageUpload.deleteButtonStatus = true;
      this.$refs.myVueDropzone.disable();
    },
    vdropzoneComplete: function (response) {},

    vdropzoneError: function (file, message, xhr) {},

    onSelectFinance: function (e) {
      this.userDataAvailable = this.userDataAvailable.filter((user) => {
        return String(user.user_id) !== String(e);
      });
      this.form.adminManager_user_id = e.user_id;
    },

    submitLevel: function (payload) {
      this.$vs.loading();
      projectService.addProjectLevels(payload).then((response) => {
        const { data } = response;
        if (data.Status == true || data.Status == "true") {
          this.$vs.loading.close();
          this.clearForm();
          this.clearImage();
          this.count = 2;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    submitAllLevels: function (project_id) {
      setTimeout((x) => {
        this.$vs.notify({
          title: "Created!",
          text: "Project Added Succesfully",
          iconPack: "feather",
          icon: "check_box",
          color: "success",
          time: 10000,
        });
      }, 8000);
      this.levels.map((level, levelIndex) => {
        level.userSelected.map((user, index) => {
          let payload = {
            project_id: project_id,
            user_level: levelIndex + 1,
            map_active: 1,
          };
          payload.user_id = user.user_id;
          let userrole = user.Employment[0].role_name;
          payload["is_moderator_user"] = 0;
          payload["is_admin_manager_user"] = 0;
          payload["is_approver"] = 1;
          if (levelIndex + 1 == 1) {
            payload["is_sign_pad"] = 1;
          } else {
            payload["is_sign_pad"] = 0;
          }
          payload["is_approver"] = 1;
          // }
          this.submitLevel(payload);
        });
      });
    },

    submitForm: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.submitStatus = false;
          this.form.isAdvanced = this.form.isAdvanced == 2 ? 0 : 1;
          let payload = {
            ...this.form,
            project_details: this.project_details,
          };
          if (this.form.isAdvanced == 1) {
            payload.moderation = 1;
          } else {
            payload.moderation = 0;
            payload.moderator_user_id = 0;
          }
          if (this.finance_user_id == "") {
            payload.finance_user_id = 0;
          } else {
            payload.finance_user_id = this.finance_user_id;
          }
          setTimeout((x) => {
            projectService
              .addProject(payload)
              .then((response) => {
                this.submitStatus = false;
                const { data } = response;
                if (data.Status == true) {
                  this.submitAllLevels(data.data.project_id);
                  this.$vs.loading.close();
                  this.$vs.notify({
                    title: "Created!",
                    text: data.Message,
                    iconPack: "feather",
                    icon: "check_box",
                    color: "success",
                  });
                  this.submitStatus = false;
                  this.clearForm();
                } else {
                  this.$vs.loading.close();
                  this.$vs.notify({
                    title: "error!",
                    text: data.Message,
                    iconPack: "feather",
                    icon: "check_box",
                    color: "warning",
                  });
                }
              })
              .catch((error) => {
                this.$vs.loading.close();

                this.submitStatus = true;
              });
          }, 1000);
        } else {
          this.$vs.loading.close();
        }
      });
    },

    uploadImage: function (image) {
      let formData = new FormData();
      formData.append("images", image.target.files[0]);
      uploadService
        .uploadIcon(formData)
        .then((response) => {
          const { data } = response;
          if (!data.error) {
            this.form.project_icon = data.data.image_path;
          }
        })
        .catch((err) => {
          this.$vs.notify({
            title: "error!",
            text: err,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onSelectCompany: function ({ company_id }) {
      this.auditor_value = [];
      this.finance_value = [];
      this.submitStatus = true;
      this.userDataAvailable = [];
      this.userDataAll = [];
      this.financeUsers = [];
      this.moderator_user_id = "";
      this.finance_user_id = "";
      this.LevelAllList = [];
      this.levels[0].Levellist = [];
      this.AuditorList = [];
      this.userSelected = [];
      this.levels = [
        {
          levelName: "1",
          userSelected: [],
          Levellist: [],
        },
      ];
      this.count = 2;
      this.adminManager_value = [];
      this.$validator.reset();

      if (company_id) {
        UserService.CompanyUsersList(company_id)
          .then((response) => {
            const { data } = response;
            if (data.Status != false) {
              this.userSelected = [];
              this.levels = [
                {
                  levelName: "1",
                  userSelected: [],
                  Levellist: [],
                },
              ];
              this.count = 2;
              data.data.map((user, index) => {
                user.id = user.user_id;
                user.label = user.user_name;
                user.name = user.user_name;
                this.userDataAll.push(user);
                this.LevelAllList.push(user);
                this.levels[0].Levellist.push(user);
              });
              this.userDataAvailable = this.userDataAll;
              this.financeUsers = this.userDataAll;
            }
          })
          .catch((error) => {
            this.$vs.notify({
              title: "error!",
              text: error.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          });
      }
    },
    clearForm: function () {
      this.companies = [];
      this.company_value = [];
      this.getCompanyList();
      this.form = {
        project_name: "",
        adminManager_user_id: 2,
        moderator_user_id: 2,
        project_icon: "-",
        company_id: "",
        project_active: 1,
        project_budgetactive: 1,
        contract_budgetactive: 1,
        isAdvanced: 2,
        project_type: "",
        show_type: "",
        directors: "",
        writers: "",
        lead_cast: "",
        internal_cd: "",
        episodes: "",
        shoot_days: "",
      };
      this.moderator_user_id = "";
      this.clearImage();
      this.userDataAvailable = [];
      this.userSelected = [];
      this.levels = [
        {
          levelName: "1",
          userSelected: [],
          Levellist: [],
        },
      ];
      this.count = 2;
      this.auditor_value = [];
      this.finance_value = [];
      this.project_details = "";
      this.adminManager_value = [];
      this.submitStatus = true;
      if (this.checkUserType == 0) {
        this.form.isAdvanced = 1;
      } else if (this.checkUserType == 1) {
        this.form.isAdvanced = 2;
      }
      this.$validator.reset();
    },

    onAddLevel: async function () {
      let array = [];
      let promises = this.levels.map((x) => {
        x.userSelected.map((y) => {
          array.push(y.user_id);
          return y;
        });
        return x;
      });
      await Promise.all(promises);
      array = [...new Set(array)];
      let list = [];
      this.LevelAllList.map((x) => {
        let findindex = array.findIndex((y) => String(y) === String(x.user_id));
        if (findindex === -1) list.push(x);
      });
      this.levels.push({
        levelName: this.count,
        Levellist: list,
      });
      let levelLength = this.levels.length;
      this.currentLevel = this.levels[levelLength - 1];
      this.count++;
    },
    onRemoveLevel: function (level) {
      level.userSelected.map((user, index) => {
        this.userDataAvailable.push(user);
      });
      this.levels.pop();
      this.count--;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getCompanyList: function () {
      companyService
        .getAllCompanies()
        .then((response) => {
          const { data } = response;
          if (!data.err || data.Status != false) {
            this.companies = data.data;
            if (this.companies.length > 0) {
              this.company_value = this.companies[0];
              this.form.company_id = this.company_value.company_id;

              this.onSelectCompany(this.company_value);
            }
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getAdminManeger: function () {
      let id = this.company_value.company_id;
      projectService
        .getAdminManagerUsers(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.adminManegerUsers = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getModerator: function () {
      let id = this.company_value.company_id;
      projectService
        .getModeratorUsers(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.AuditorList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
  },
  mounted() {
    this.getCompanyList();
    this.gridApi = this.gridOptions.api;
    if (this.checkUserType == 0) {
      this.form.isAdvanced = 1;
    } else if (this.checkUserType == 1) {
      this.form.isAdvanced = 2;
      this.form.contract_budgetactive = 1;
    } else {
      this.form.contract_budgetactive = 1;
    }
  },
};
</script>
